import { DocusignOrManulForm, FormType } from '@fastre/core/src/schemas/docusign'
import { formatAddress } from '@fastre/core/src/schemas/generic'
import { getFormName } from '@fastre/core/src/schemas/rei'
import {
    AddRounded,
    Check,
    DeleteRounded,
    DraftsRounded,
    MoreVertRounded,
    MoveToInbox,
    SendRounded,
    UploadFileRounded,
    Visibility,
} from '@mui/icons-material'
import {
    Box,
    Button,
    Dropdown,
    IconButton,
    Link,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem,
    Stack,
    Step,
    StepIndicator,
    Stepper,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useUserData } from 'auth'
import PdfModal from 'components/pdfModal'
import { useShowSnack } from 'components/snackbar'
import { useIsMobile } from 'helperFunctions/pageSize'
import { useOrgId } from 'helpers'
import OptionalTooltip from 'links/optionalTooltip'
import { prop } from 'ramda'
import { useState } from 'react'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { docusignConnectionUrl } from 'settings/docusignConnectionResponse'
import ManualFormUploadModal from '../formStepper/uploadFormModal'
import { useListingContext } from '../listingProvider'
import { AbandonFormModal } from './abandonFormModal'
import AddAttachmentModal from './addAttachmentsModal'
import IframeModal from './iframeModal'
import UploadFormModal from './uploadFormModal'

/*
| 'created' //: The envelope is created as a draft. It can be modified and sent later.
    | 'sent' //: The envelope will be sent to the recipients after the envelope is created.
    | 'delivered' //: The envelope has been delivered to the recipients.
    | 'signed' //: The envelope has been signed by the recipients.
    | 'completed' //: The recipients have finished working with the envelope: the documents are signed and all required tabs are filled in.
    | 'declined' //: The envelope has been declined by the recipients.
    | 'voided' //: The envelope is no longer valid and recipients cannot access or sign the envelope.
    */

enum FormStage {
    'voided' = -2,
    'declined' = -1,
    'none' = 0,
    'createdInRei',
    'created',
    'sent',
    'completed',
    'manual',
}

const getFormStage = (form: DocusignOrManulForm | undefined): FormStage => {
    if (form == undefined) {
        return FormStage.none
    }

    switch (form.formStatus) {
        case 'voided':
            return FormStage.voided
        case 'declined':
            return FormStage.declined
        case 'completed':
        case 'signed':
            return FormStage.completed
        case 'sent':
        case 'delivered':
            return FormStage.sent
        case 'created in rei':
            return FormStage.createdInRei
        case 'created':
            return FormStage.created
        case 'manual upload':
            return FormStage.manual
        default:
            return FormStage.none
    }
}

interface Props {
    title: string
    formType: FormType
    createDisabled: boolean | string
    validate: () => Promise<boolean> | boolean
    preCreate: () => Promise<boolean> | boolean
    roleNames: string[]
}

const DocusignFormStepper = ({ title, formType, createDisabled, validate, preCreate, roleNames }: Props) => {
    const { listing, refreshListing, setListing } = useListingContext()
    const api = useApi()
    const { user } = useUserData()
    const showSnack = useShowSnack()
    const isMobile = useIsMobile()
    const orgId = useOrgId()

    const [viewLoading, setViewLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [previewPdf, setPreviewPdf] = useState<{ url: string; useAuth: boolean }>()
    const [editUrl, setEditUrl] = useState<string>()
    const [showUploadForm, setShowUploadForm] = useState(false)
    const [showAbandonFormDialog, setShowAbandonFormDialog] = useState<FormType | undefined>()
    const [showAddAttachmentsModal, setShowAddAttachmentsModal] = useState(false)
    const [showManualUploadModal, setShowManualUploadModal] = useState<FormType | undefined>()

    const form = listing[formType]
    const formStage = getFormStage(form)
    const formName = getFormName(formType)

    const manualFileUpload = formType == 'disclosureStatementForm'
    const requiresDisclosure = formType == 'contractForm' && listing.propertyType == 'cts'
    const disclosureCreated = listing.disclosureStatementForm != undefined
    const disclosureComplete = listing.disclosureStatementForm?.formStatus == 'completed'

    const createDisabledBoolean = typeof createDisabled == 'string' ? true : createDisabled

    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 6 }}>
                <Typography
                    level="h4"
                    sx={{ m: 0 }}
                >
                    {title}
                </Typography>
                <Dropdown>
                    <MenuButton
                        slots={{ root: IconButton }}
                        slotProps={{ root: { size: 'sm' } }}
                    >
                        <MoreVertRounded fontSize="small" />
                    </MenuButton>
                    <Menu>
                        {formStage < FormStage.completed && (
                            <MenuItem onClick={() => setShowManualUploadModal(formType)}>
                                <ListItemDecorator>
                                    <UploadFileRounded />
                                </ListItemDecorator>
                                Upload Form
                            </MenuItem>
                        )}
                        {formStage != FormStage.none && (
                            <MenuItem onClick={() => setShowAbandonFormDialog(formType)}>
                                <ListItemDecorator>
                                    <DeleteRounded />
                                </ListItemDecorator>
                                Abandon Form
                            </MenuItem>
                        )}
                    </Menu>
                </Dropdown>
                <Box sx={{ flexGrow: 1 }} />
                {formStage !== FormStage.none && (
                    <Box>
                        <Button
                            variant="outlined"
                            loading={viewLoading}
                            onClick={async () => {
                                switch (form?.formStatus) {
                                    case 'completed':
                                    case 'manual upload':
                                        setViewLoading(true)
                                        const completedUrl = await api
                                            .get(
                                                `/listing/sale/${listing.listingId}/docusign/${formType}/viewcompletedform`,
                                            )
                                            .then(prop('data'))
                                        setPreviewPdf({ url: completedUrl, useAuth: false })
                                        setViewLoading(false)
                                        break
                                    case 'created':
                                        setViewLoading(true)
                                        const { url } = await api
                                            .post(
                                                `/listing/sale/${listing.listingId}/docusign/${formType.toLocaleLowerCase()}/edit`,
                                                {
                                                    returnUrl: `${window.location.origin}/docusign/close`,
                                                },
                                            )
                                            .then(prop('data'))

                                        setEditUrl(url)
                                        setViewLoading(false)
                                        break
                                    case 'created in rei':
                                        // Open REI view
                                        setViewLoading(true)
                                        const data = await api
                                            .get(
                                                `listing/sale/${listing.listingId}/rei/${formType.toLocaleLowerCase()}`,
                                            )
                                            .then(prop('data'))
                                        window.open(data.url, '_blank')
                                        setViewLoading(false)
                                        break
                                    default:
                                        /*const doc = await api
                                            .get(`/docusign/${form?.envelopeId}/view`)
                                            .then(prop('data'))
                                        setPreviewPdf({ url: doc, useAuth: false })*/

                                        setPreviewPdf({
                                            url: `${import.meta.env.VITE_APP_API_URL}/${orgId}/docusign/${form?.envelopeId}/view`,
                                            useAuth: true,
                                        })
                                        break
                                }
                            }}
                            startDecorator={<Visibility />}
                        >
                            View Form
                        </Button>
                    </Box>
                )}
                {formStage == FormStage.none && form?.formStatus != 'created in rei' && (
                    <OptionalTooltip
                        show={typeof createDisabled == 'string'}
                        title={createDisabled as string}
                    >
                        <Box>
                            <Button
                                loading={loading}
                                onClick={async () => {
                                    if (await validate()) {
                                        setLoading(true)
                                        const pass = await preCreate()
                                        if (pass) {
                                            switch (formType) {
                                                case 'contractForm':
                                                    //setShowAddAttachmentsModal(true)
                                                    const data = await api
                                                        .post(
                                                            `/listing/sale/${listing.listingId}/rei/contractform/create`,
                                                        )
                                                        .then(prop('data'))
                                                    setListing(data.updatedListing)
                                                    window.open(data.url, '_blank')
                                                    break
                                                case 'disclosureStatementForm':
                                                    setShowUploadForm(true)
                                                    break
                                                default:
                                                    const updatedListing = await api
                                                        .post(
                                                            `/listing/sale/${listing.listingId}/${formType.toLocaleLowerCase()}/create`,
                                                        )
                                                        .then(prop('data'))
                                                    if (updatedListing) {
                                                        setListing(updatedListing)
                                                    } else {
                                                        await refreshListing()
                                                    }
                                                    showSnack(`${formName} created`, 'success')
                                            }
                                        }
                                    }
                                    setLoading(false)
                                }}
                                startDecorator={<AddRounded />}
                                disabled={createDisabledBoolean}
                            >
                                {manualFileUpload ? 'Upload' : 'Create'} Form
                            </Button>
                        </Box>
                    </OptionalTooltip>
                )}
                {user.docusign?.userInfo != undefined && (
                    <>
                        {formType == 'contractForm' && form?.formStatus == 'created in rei' && (
                            <Box>
                                <Button
                                    loading={loading}
                                    onClick={async () => {}}
                                    startDecorator={<DraftsRounded />}
                                    disabled={createDisabledBoolean}
                                    onClickCapture={() => setShowAddAttachmentsModal(true)}
                                >
                                    Send to Docusign
                                </Button>
                            </Box>
                        )}

                        {formStage == FormStage.created && (
                            <OptionalTooltip
                                show={user.docusign != undefined}
                                title="Connect your DocuSign account in order to send forms"
                            >
                                <Box>
                                    <Button
                                        loading={loading}
                                        onClick={async () => {
                                            setLoading(true)
                                            try {
                                                await api.post(
                                                    `/listing/sale/${listing.listingId}/${formType.toLocaleLowerCase()}/send`,
                                                )
                                                await refreshListing()
                                                showSnack(`${formName} sent`, 'success')
                                            } catch (e) {
                                                console.error(e)
                                                showSnack(`Error sending ${formName}`, 'danger')
                                            } finally {
                                                setLoading(false)
                                            }
                                        }}
                                        startDecorator={<SendRounded />}
                                    >
                                        Send Form
                                    </Button>
                                </Box>
                            </OptionalTooltip>
                        )}
                    </>
                )}
            </Box>
            {user.docusign == undefined && (
                <Typography sx={{ mt: 2 }}>
                    <Link href={docusignConnectionUrl(user)}>Connect</Link> for DocuSign integration or upload
                    form manually
                </Typography>
            )}
            {formStage == FormStage.manual && (
                <Typography
                    level="body-lg"
                    sx={{ mt: 1 }}
                >
                    Form manually uploaded
                </Typography>
            )}
            {user.docusign != undefined && formStage != FormStage.manual && (
                <>
                    <Stepper
                        //orientation={isMobile ? 'vertical' : 'horizontal'}
                        orientation="vertical"
                        sx={{ width: '100%', mt: 2 }}
                    >
                        {requiresDisclosure && (
                            <>
                                <Step
                                    indicator={
                                        <StepIndicator
                                            variant={disclosureCreated ? 'solid' : 'soft'}
                                            color="primary"
                                        >
                                            {disclosureCreated ? <Check /> : undefined}
                                        </StepIndicator>
                                    }
                                    sx={{
                                        '&::after': {
                                            ...(disclosureCreated && {
                                                bgcolor: 'primary.solidBg',
                                            }),
                                        },
                                    }}
                                >
                                    <Typography>Create Disclosure Statement</Typography>
                                </Step>
                                <Step
                                    indicator={
                                        <StepIndicator
                                            variant={disclosureComplete ? 'solid' : 'soft'}
                                            color={disclosureCreated ? 'primary' : 'neutral'}
                                        >
                                            {disclosureComplete ? <Check /> : undefined}
                                        </StepIndicator>
                                    }
                                    sx={{
                                        '&::after': {
                                            ...(disclosureComplete && {
                                                bgcolor: 'primary.solidBg',
                                            }),
                                        },
                                    }}
                                >
                                    <Typography>Disclosure Statement Signed</Typography>
                                </Step>
                            </>
                        )}

                        {formType == 'contractForm' && (
                            <Step
                                indicator={
                                    <StepIndicator
                                        variant={
                                            FormStage.created <= formStage ||
                                            form?.formStatus == 'created in rei'
                                                ? 'solid'
                                                : 'soft'
                                        }
                                        color={
                                            !requiresDisclosure || disclosureComplete ? 'primary' : 'neutral'
                                        }
                                    >
                                        {formStage != FormStage.none ||
                                        form?.formStatus == 'created in rei' ? (
                                            <Check />
                                        ) : undefined}
                                    </StepIndicator>
                                }
                                sx={{
                                    '&::after': {
                                        ...((FormStage.created <= formStage ||
                                            form?.formStatus == 'created in rei') && {
                                            bgcolor: 'primary.solidBg',
                                        }),
                                    },
                                }}
                            >
                                <Typography>Create in Realworks</Typography>
                            </Step>
                        )}

                        {/* Create */}
                        <Step
                            indicator={
                                <StepIndicator
                                    variant={FormStage.created <= formStage ? 'solid' : 'soft'}
                                    color={
                                        formType != 'contractForm' ||
                                        formStage >= FormStage.created ||
                                        form?.formStatus == 'created in rei'
                                            ? 'primary'
                                            : 'neutral'
                                    }
                                >
                                    {formStage != FormStage.none ? <Check /> : undefined}
                                </StepIndicator>
                            }
                            sx={{
                                '&::after': {
                                    ...(FormStage.created <= formStage && {
                                        bgcolor: 'primary.solidBg',
                                    }),
                                },
                            }}
                        >
                            <Typography>
                                {manualFileUpload
                                    ? 'Upload'
                                    : formType == 'contractForm'
                                      ? 'Send to DocuSign'
                                      : 'Create'}
                            </Typography>
                        </Step>
                        {/* Send */}
                        <Step
                            indicator={
                                <StepIndicator
                                    variant={FormStage.sent <= formStage ? 'solid' : 'soft'}
                                    color={FormStage.sent <= formStage + 1 ? 'primary' : 'neutral'}
                                >
                                    {formStage >= FormStage.sent ? <Check /> : undefined}
                                </StepIndicator>
                            }
                            sx={{
                                '&::after': {
                                    ...(formStage >= FormStage.sent && {
                                        bgcolor: 'primary.solidBg',
                                    }),
                                },
                            }}
                        >
                            <Typography>Send</Typography>
                        </Step>

                        {(form?.formStatus != 'created in rei' && form?.formStatus != 'manual upload'
                            ? form?.recipients
                            : undefined
                        )?.signers.map(signer => (
                            <Step
                                key={signer.recipientId}
                                indicator={
                                    <StepIndicator
                                        variant={signer.status == 'completed' ? 'solid' : 'soft'}
                                        color={signer.status == 'completed' ? 'primary' : 'neutral'}
                                    >
                                        {signer.status == 'completed' ? (
                                            <Check />
                                        ) : signer.status == 'delivered' ? (
                                            <MoveToInbox />
                                        ) : undefined}
                                    </StepIndicator>
                                }
                                sx={{
                                    '&::after': {
                                        ...(signer.status == 'completed' && {
                                            bgcolor: 'primary.solidBg',
                                        }),
                                    },
                                }}
                            >
                                <Stack>
                                    <Typography>{signer.roleName} Signature</Typography>
                                </Stack>
                            </Step>
                        )) ??
                            roleNames.map(roleName => (
                                <Step
                                    key={roleName}
                                    indicator={
                                        <StepIndicator
                                            variant="soft"
                                            color="neutral"
                                        />
                                    }
                                >
                                    <Stack>
                                        <Typography>{roleName} Signature</Typography>
                                    </Stack>
                                </Step>
                            ))}
                    </Stepper>
                </>
            )}
            <PdfModal
                title={title}
                pdf={previewPdf?.url}
                onClose={() => setPreviewPdf(undefined)}
                fileName={`${formatAddress(listing.listingAddress)}_${formType}.pdf`}
                hideDownloadButton={
                    listing.contractForm?.formStatus != 'created in rei' && formStage != FormStage.manual
                }
                withAuth={previewPdf?.useAuth}
            />
            <IframeModal
                title={getFormName(formType)}
                url={editUrl}
                onClose={() => setEditUrl(undefined)}
            />
            <UploadFormModal
                open={showUploadForm}
                onClose={url => {
                    setShowUploadForm(false)
                    if (url) {
                        console.log('url', url)
                        setEditUrl(url)
                    }
                }}
                formType={formType}
            />
            <AbandonFormModal
                open={showAbandonFormDialog != undefined}
                onClose={() => setShowAbandonFormDialog(undefined)}
                formType={showAbandonFormDialog}
            />
            <AddAttachmentModal
                open={showAddAttachmentsModal}
                onClose={() => setShowAddAttachmentsModal(false)}
                setEditUrl={setEditUrl}
            />
            <ManualFormUploadModal
                open={showManualUploadModal != undefined}
                close={() => setShowManualUploadModal(undefined)}
                formType={showManualUploadModal!}
            />
        </>
    )
}

export default DocusignFormStepper
