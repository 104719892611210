import { reduceToObject } from '@fastre/core/src/helperFunctions/array'
import { roundTo2 } from '@fastre/core/src/helperFunctions/number'
import { Link, Stack, Typography } from '@mui/joy'
import { useUserData } from 'auth'
import PdfModal from 'components/pdfModal'
import { useOrgId } from 'helpers'
import DocusignFormStepper from 'listings/docusignFormStepper.tsx'
import { useListingContext } from 'listings/listingProvider'
import { sum } from 'ramda'
import { useState } from 'react'

export default () => {
    const { user } = useUserData()
    const orgId = useOrgId()
    const { listing, refreshListing } = useListingContext()

    const [pdfFile, setPdfFile] = useState<string>()

    if (listing.fundDisbursementForm?.formStatus == 'created in rei') {
        return <>error</>
    }

    const tabValues = listing.fundDisbursementForm?.recipients?.signers[0]?.tabs?.textTabs
        ?.map(tab => ({
            [tab.tabLabel]: tab.value,
        }))
        .reduce(reduceToObject, {})

    if (user.docusign != undefined && !user.fundDisbursementFormConfigured) {
        if (user.permissions.includes('settings.edit')) {
            return (
                <>
                    <Typography
                        level="h4"
                        sx={{ mt: 20 }}
                    >
                        Fund Disbursement Form
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        <Link href={`/${orgId}/settings?tab=Fund+Disbursement+Template`}>Configure</Link> your
                        fund disbursement form to create, send and view forms
                    </Typography>
                </>
            )
        }
    }

    console.log(sum(listing.balanceSheet?.map(x => (x.type == 'credit' ? x.amount : -x.amount)) ?? [0]))

    return (
        <>
            <DocusignFormStepper
                title="Fund Disbursement Form"
                //form={listing.fundDisbursementForm}
                formType="fundDisbursementForm"
                validate={() => true}
                preCreate={() => Promise.resolve(true)}
                createDisabled={
                    listing.balanceSheet.findIndex(x => x.externalRef == 'vendorBalance') == -1 ||
                    roundTo2(
                        sum(
                            listing.balanceSheet?.map(x => (x.type == 'credit' ? x.amount : -x.amount)) ?? [
                                0,
                            ],
                        ),
                    ) != 0
                }
                roleNames={listing.vendors.map((vendor, i) => `Vendor ${i + 1}`)}
            />
            {listing.fundDisbursementForm && (
                <Stack>
                    {listing.fundDisbursementForm.formStatus == 'completed' && (
                        <>
                            <Typography>Bank Name: {tabValues?.bank}</Typography>
                            <Typography>Account Name: {tabValues?.accountName}</Typography>
                            <Typography>BSB: {tabValues?.bsb}</Typography>
                            <Typography>Account Number: {tabValues?.accountNumber}</Typography>
                        </>
                    )}
                </Stack>
            )}
            <PdfModal
                title="Fund Disbursement Form"
                pdf={pdfFile}
                onClose={() => setPdfFile(undefined)}
                fileName="fund-disbursement-form"
                withAuth
            />
        </>
    )
}
